import { Link } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
import { SelectedSoaps } from '../../types/yakureki'
import { soapLabels } from '../../constants/const'

type Props = {
  soapKey: keyof SelectedSoaps
  targetSoapContents: string[]
  selectedSoapContents: SelectedSoaps
  deleteSpecificSoaps: (key: keyof SelectedSoaps, targetSoaps: string[]) => void
  addSpecificSoaps: (key: keyof SelectedSoaps, targetSoaps: string[]) => void
  update: (k: keyof SelectedSoaps, v: string) => void
}

export const SoapCheckBoxes: FC<Props> = ({
  soapKey,
  targetSoapContents,
  selectedSoapContents,
  deleteSpecificSoaps,
  addSpecificSoaps,
  update,
}) => {
  return (
    <Stack>
      {targetSoapContents.length !== 0 && (
        <FormControlLabel
          sx={{ color: '#000', alignItems: 'start' }}
          control={
            <Checkbox
              sx={{ height: 24, pr: 1, pl: 2 }}
              size="small"
              color="info"
              checked={
                selectedSoapContents[soapKey].length ===
                targetSoapContents.length
              }
              onChange={(e) =>
                selectedSoapContents[soapKey].length ===
                targetSoapContents.length
                  ? deleteSpecificSoaps(soapKey, targetSoapContents)
                  : addSpecificSoaps(soapKey, targetSoapContents)
              }
              value={soapLabels[soapKey]}
            />
          }
          label={soapLabels[soapKey]}
        />
      )}

      {targetSoapContents.map((s) => (
        <FormControlLabel
          key={s}
          sx={{ color: '#000', alignItems: 'start' }}
          control={
            <Checkbox
              sx={{ height: 24, pr: 1, pl: 4 }}
              size="small"
              color="info"
              checked={selectedSoapContents[soapKey].includes(s)}
              onChange={(e) => update(soapKey, e.target.value)}
              value={s}
            />
          }
          label={s}
        />
      ))}
    </Stack>
  )
}
