import { useState, useCallback, FC } from 'react'
import { memo, NamedExoticComponent } from 'react'
import { Box, Button, useTheme } from '@mui/material'
import { MenuIcon } from '../icons/MenuIcon'
import { DrawerPrescList } from '../components/organisms/Drawer'
import { Yakureki } from '../types/yakureki'
import { serviceName } from '../constants/const'

type Props = {
  selectedYakureki?: Yakureki
  yakurekiList: Yakureki[]
  selectYakureki: (yakurekiId: string) => void
  BottomButtons: React.ReactNode[]
}

export const DrawerToggleButton: NamedExoticComponent<Props> = memo(
  ({ selectedYakureki, yakurekiList, selectYakureki, BottomButtons }) => {
    const [isOpen, setIsOpen] = useState(false)
    const theme = useTheme()
    const open = useCallback(() => setIsOpen(true), [])
    const close = useCallback(() => setIsOpen(false), [])

    return (
      <>
        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              bottom: '0',
              borderRadius: '0px 8px 0px 0px',
            },
            opacity: 0.8,
          }}
          position="fixed"
          zIndex={1300}
          left={0}
          bgcolor={'primary.dark'}
          color="#fff"
          p={1}
          borderRadius="0px 0px 8px 0px"
        >
          <Button
            onClick={open}
            startIcon={<MenuIcon />}
            style={{ color: '#fff' }}
          >
            {selectedYakureki ? selectedYakureki.label : serviceName}
          </Button>
        </Box>
        <DrawerPrescList
          isOpen={isOpen}
          onClose={close}
          yakurekiList={yakurekiList}
          selectedYakureki={selectedYakureki}
          selectYakureki={selectYakureki}
          BottomButtons={BottomButtons}
        />
      </>
    )
  }
)

DrawerToggleButton.displayName = 'DrawerOpenButton'
