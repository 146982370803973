import { Button, Stack, TextField } from '@mui/material'
import React, { FC, useState } from 'react'

type Props = {
  name: string
  setName: (name: string) => void
  isRecording: boolean
  audioURL?: string | null
  recordButton: JSX.Element
}

export const RecordModalBody: FC<Props> = ({
  name,
  setName,
  isRecording,
  audioURL,
  recordButton,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <Stack direction="column" spacing={2}>
        <TextField
          label={'患者氏名'}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {recordButton}
      </Stack>
    </div>
  )
}
