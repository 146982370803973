export const formatTimeInJP = (time: string): string => {
  const timeInJp = new Date(time)

  //時間と分を二桁に統一する
  const hours = ('0' + timeInJp.getHours().toString()).slice(-2)
  const minutes = ('0' + timeInJp.getMinutes().toString()).slice(-2)

  return hours + ':' + minutes
}

export const formatTimeInJPFull = (time: string): string => {
  const timeInJp = new Date(time)

  //時間と分を二桁に統一する
  const month = ('0' + (timeInJp.getMonth() + 1).toString()).slice(-2)
  const day = ('0' + timeInJp.getDate().toString()).slice(-2)
  const hours = ('0' + timeInJp.getHours().toString()).slice(-2)
  const minutes = ('0' + timeInJp.getMinutes().toString()).slice(-2)

  return month + '/' + day + '　' + hours + ':' + minutes
}

export const getCurrentDateString = () => {
  const now = new Date()
  const year = now.getFullYear() // 年を取得
  const month = now.getMonth() + 1 // 月を取得（0から始まるため+1する）
  const day = now.getDate() // 日を取得

  // 数値を2桁の文字列に変換する関数
  const formatNumber = (num: number): string => `0${num}`.slice(-2)

  return `${year}/${formatNumber(month)}/${formatNumber(day)}`
}
