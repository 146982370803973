import { useMemo, useState } from 'react'
import { Yakureki } from '../types/yakureki'
import useReactRouter from 'use-react-router'

export const useSelectedYakureki = (yakurekiList: Yakureki[]) => {
  const { history } = useReactRouter()
  const [selecteId, setSelecteId] = useState<Yakureki['id']>()

  const findYakureki = (id: Yakureki['id']) => {
    const prescription = yakurekiList.find((yaku) => yaku.id === id)
    return prescription
  }

  const selectedYakureki = useMemo(
    () =>
      (selecteId && findYakureki(selecteId)) ||
      yakurekiList.find((yakureki) => !yakureki.is_progress),
    [selecteId, yakurekiList]
  )

  const selectRequest = (requestId: string) => {
    setSelecteId(requestId)
  }

  return {
    selectedYakureki,
    selectRequest,
  }
}
