import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../stores/reducer'
import { getYakurekiList } from '../apis/yakureki'
import { Yakureki } from '../types/yakureki'

export const useYakurekiList = () => {
  const [yakurekiList, setYakurekiList] = useState<Yakureki[]>([])
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return
    const intervalId = window.setInterval(loadYakurekiList, 1000 * 1)
    return () => {
      clearInterval(intervalId)
    }
  }, [isLoggedIn])

  const loadYakurekiList = async () => {
    try {
      const { data } = await getYakurekiList()
      console.log('YakurekiList', data)
      setYakurekiList(data)
    } catch (e) {
      console.log(e)
    }
  }

  return {
    yakurekiList,
    loadYakurekiList,
  }
}
