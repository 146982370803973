import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../stores/reducer'
import { getYakurekiDetail } from '../apis/yakureki'
import { SelectedSoaps, Yakureki, YakurekiDetail } from '../types/yakureki'

export const useYakurekiDetail = (requestId: string | undefined) => {
  const [yakurekiDetail, setYakurekiDetail] = useState<YakurekiDetail>()
  const [soapList, setSoapList] = useState<SelectedSoaps>()
  const [selectedSoaps, setSelectedSoaps] = useState<SelectedSoaps | undefined>(
    soapList
  )
  const isLoggedIn = useSelector((state: GlobalState) => state.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return
    if (!requestId) return
    loadYakurekiDetail()
  }, [isLoggedIn, requestId])

  const loadYakurekiDetail = async () => {
    if (!requestId) return
    try {
      const { data } = await getYakurekiDetail(requestId)
      console.log('YakurekiDetail', data)
      setYakurekiDetail(data)
      if (!data.soaps) return
      const { id, ...soapsWithoutId } = data.soaps
      setSoapList(soapsWithoutId)
      setSelectedSoaps(soapsWithoutId)
    } catch (e) {
      console.log(e)
    }
  }

  return {
    yakurekiDetail,
    loadYakurekiDetail,
    soapList,
    selectedSoaps,
    setSelectedSoaps,
  }
}
