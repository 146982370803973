import React, { FC } from 'react'
import { Typography } from '@mui/material'

type Props = {
  speaker: string
  original_string: string
  lack_confident_strings: string[]
}

export const DictatedContentTypography: FC<Props> = ({
  speaker,
  original_string,
  lack_confident_strings,
}) => {
  const highlightStyle = {
    color: '#A1A3A6',
    borderBottom: '2px dotted #A1A3A6',
  } // 赤色にハイライトするスタイル
  const normalStyle = { color: 'black' } // 通常のテキストスタイル
  const regex = new RegExp(`(${lack_confident_strings.join('|')})`, 'gi')
  const parts = original_string.split(regex)

  return (
    <Typography>
      {speaker}:{' '}
      {parts.map((part, index) =>
        lack_confident_strings.includes(part) ? (
          <span key={index} style={highlightStyle}>
            {part}
          </span>
        ) : (
          <span key={index} style={normalStyle}>
            {part}
          </span>
        )
      )}
    </Typography>
  )
}
