import { Link } from '@mui/icons-material'
import { Typography } from '@mui/material'
import React, { FC } from 'react'

export const Copyright: FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © Medilab. ${new Date().getFullYear()}. ver.0.0.1`}
    </Typography>
  )
}
