import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkAuth } from '../apis/auth'

type User = {
  id: string
  pharmacy_name: string
  plan: string
}

type ResponseData = {
  version: null
  user: User
}

export const useRedirectToLogin = (): void => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    checkIsLoggedIn()
  }, [])

  const checkIsLoggedIn = async () => {
    try {
      const response = await checkAuth()
      console.log(response)
      onSuccess(response.data)
    } catch (error) {
      onFail()
      console.error(error)
    }
  }

  const onSuccess = (responseData: ResponseData) => {
    dispatchIsLoggedIn()
  }

  const onFail = () => {
    navigateToLogin()
  }

  const dispatchIsLoggedIn = () => {
    dispatch({ type: 'setIsLoggedIn', isLoggedIn: true })
  }

  const navigateToLogin = () => {
    history.push('/login')
  }
}
