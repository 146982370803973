import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  IconButton,
  Typography,
  Stack,
  CircularProgress,
  Snackbar,
} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import MicIcon from '@mui/icons-material/Mic'
import useSound from 'use-sound'
import { theme } from '../styles/theme'
import { postYakurekiAudio } from '../apis/yakureki'

export const useRecordAudio = (
  name: string,
  setName: (name: string) => void,
  closeModal: () => void,
  fetch: () => void
) => {
  const [audioURL, setAudioURL] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSendingComplete, setIsSendingComplete] = useState<boolean>(false)
  const [SoapOwnerName, setSoapOwnerName] = useState<string>('')
  const [isAudioStarted, setIsAudioStarted] = useState<boolean>(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    // ユーザーからのマイクアクセス許可をリクエスト
    const getMicrophoneAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const mediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
      } catch (error) {
        console.error('Error accessing microphone:', error)
      }
    }
    getMicrophoneAccess()
  }, [])

  const playSound = () => {
    if (audioRef.current) {
      if (isAudioStarted) {
        audioRef.current.pause()
      } else {
        audioRef.current.currentTime = 0
        audioRef.current.play()
      }
      setIsAudioStarted(!isAudioStarted)
    }
  }

  const startRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'inactive') {
      mediaRecorder.start()
      setIsRecording(true)

      const audioChunks: BlobPart[] = []
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunks.push(event.data)
      }

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks)
        const audioUrl = URL.createObjectURL(audioBlob)
        setAudioURL(audioUrl)
        console.log('audioUrl', audioUrl)
        setIsRecording(false)
      }
    }
  }

  const sendAudio = async () => {
    // TODO:実際に非同期処理を行えるようになったらpost後にモーダルを閉じるようにする
    // TODO:setTmpNameもデモ用の負債なので非同期処理を行えるようになったらpost後にモーダルを閉じるようにする
    setIsSending(true)
    setAudioURL(null)
    setSoapOwnerName(name)
    setName('')
    closeModal()
    audioURL && (await postYakurekiAudio(audioURL, name))
    setIsSending(false)
    setIsSendingComplete(true)
    fetch()
  }

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop()
    }
  }

  const recordButton = (
    <>
      {audioURL ? (
        <>
          <audio ref={audioRef} src={audioURL} />
          <IconButton size="small" onClick={() => playSound()}>
            {isAudioStarted ? (
              <PauseCircleIcon style={{ fontSize: 400 }} />
            ) : (
              <PlayCircleFilledWhiteIcon style={{ fontSize: 400 }} />
            )}
          </IconButton>
          <Stack
            direction="row"
            width="100%"
            justifyContent={'space-between'}
            spacing={2}
          >
            <Button
              sx={{
                color: '#fff',
                bgcolor: theme.palette.primary.dark,
                '&:hover': {
                  bgcolor: theme.palette.primary.main, // ホバー時の背景色
                  color: '#000', // ホバー時の文字色
                },
                width: '50%',
              }}
              onClick={() => setAudioURL(null)}
            >
              録り直し
            </Button>
            <Button
              sx={{
                color: '#fff',
                bgcolor: theme.palette.primary.dark,
                '&:hover': {
                  bgcolor: theme.palette.primary.main, // ホバー時の背景色
                  color: '#000', // ホバー時の文字色
                },
                width: '50%',
              }}
              onClick={sendAudio}
            >
              SOAP作成
            </Button>
          </Stack>
        </>
      ) : (
        <>
          {isRecording ? (
            <>
              <Typography>録音中...</Typography>
              <MicIcon style={{ fontSize: 400 }} />
              <Button
                sx={{
                  color: '#fff',
                  bgcolor: theme.palette.primary.dark,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                }}
                onClick={stopRecording}
              >
                録音停止
              </Button>
            </>
          ) : (
            <>
              <MicIcon style={{ fontSize: 400 }} />
              <Button
                sx={{
                  color: '#fff',
                  bgcolor: theme.palette.primary.dark,
                  '&:hover': {
                    bgcolor: theme.palette.primary.main, // ホバー時の背景色
                    color: '#000', // ホバー時の文字色
                  },
                }}
                variant="outlined"
                onClick={startRecording}
              >
                録音開始
              </Button>
            </>
          )}
        </>
      )}
    </>
  )

  return {
    isRecording,
    audioURL,
    recordButton,
  }
}
