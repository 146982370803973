import React, { FC, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { theme } from './styles/theme'
import { LoginPage } from './pages/login'
import { AppProvider } from './stores/store'
import IndexPage from './pages'
import { useRedirectToLogin } from './hooks/useRedirectToLogin'
import { useYakurekiList } from './hooks/useYakurekiList'
import { AdaptationPage } from './pages/adaptation'
import { useChangeLocalhostUrl } from './hooks/useChangeLocalhostUrl'

const Pages = () => {
  useRedirectToLogin()
  return (
    <>
      <ThemeProvider theme={theme}>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/adaptation" component={AdaptationPage} />
        <Route exact path="/login" component={LoginPage} />
      </ThemeProvider>
    </>
  )
}

const App: FC = () => {
  useChangeLocalhostUrl()
  return (
    <AppProvider>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </AppProvider>
  )
}

export default App
