import { Yakureki, YakurekiDetail } from '../types/yakureki'
import client from './client'
import { AxiosPromise } from 'axios'

export const getYakurekiDetail = (
  yakurekiId: string
): AxiosPromise<YakurekiDetail> => client.get(`/yakureki/${yakurekiId}`)

export const getYakurekiLatest = (): AxiosPromise<YakurekiDetail> =>
  client.get(`/yakureki/latest`)

export const getYakurekiList = (): AxiosPromise<Yakureki[]> =>
  client.get(`/yakureki/`)

export const postYakurekiAudio = async (
  audio: string,
  label: string
): Promise<AxiosPromise<string>> => {
  const response = await fetch(audio)
  const blob = await response.blob()
  const mp3Blob = new Blob([blob], { type: 'audio/mp3' })
  console.log('mp3Blob', mp3Blob)
  const formData = new FormData()
  formData.append('audio', mp3Blob, 'audio.mp3')
  formData.append('label', label)
  return client.post('/yakureki/', formData)
}
