import React, { FC, useState } from 'react'
import {
  AppBar,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { PlusIcon } from '../icons/PlusIcon'
import { formatDateTimeToDate } from '../helpers/formatDateTimeToDate'
import useReactRouter from 'use-react-router'
import { CustomFab } from '../components/molecules/CustomFab'
import { theme } from '../styles/theme'
import { serviceName } from '../constants/const'
import { getCurrentDateString } from '../helpers'
import { TrashIcon } from '../icons/TrashIcon'

type AdaptationWord = { word: string; createdAt: string }

export const AdaptationPage: FC = () => {
  const { history } = useReactRouter()
  const [adaptationWord, setAdaptationWord] = useState<string>('')
  const [adaptationWords, setAdaptationWords] = useState<AdaptationWord[]>([
    {
      word: 'アルブミン',
      createdAt: '2024/01/02',
    },
    {
      word: 'カルボシステイン',
      createdAt: '2024/01/01',
    },
  ])

  const submit = () => {
    const createdAt = getCurrentDateString()
    setAdaptationWords([
      { word: adaptationWord, createdAt: createdAt },
      ...adaptationWords,
    ])
    setAdaptationWord('')
  }

  const onDelete = (adaptation: AdaptationWord) => {
    const removedAdaptation = adaptationWords.filter(
      (item) => item.word !== adaptation.word
    )
    setAdaptationWords(removedAdaptation)
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{ bgcolor: theme.palette.primary.dark, color: '#fff' }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="contained" onClick={() => history.push('/')}>
            薬歴一覧画面へ戻る
          </Button>
          <Typography variant="h5">
            {serviceName} アルゴリズム管理画面
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack direction="column" spacing={2} p={2} pt={6} alignItems={'center'}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <TextField
            sx={{ width: 600 }}
            label={'重要語句登録'}
            value={adaptationWord}
            onChange={(e) => setAdaptationWord(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: theme.palette.primary.dark,
              color: '#fff',
              height: 60,
            }}
            onClick={submit}
          >
            登録
          </Button>
        </Stack>
        <Stack direction="column" width={800} pt={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: theme.palette.secondary.main }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>登録日</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>重要語句</TableCell>
                  <TableCell sx={{ width: 60 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adaptationWords.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.createdAt}</TableCell>
                    <TableCell>{item.word}</TableCell>
                    <TableCell>
                      <IconButton
                        component="span"
                        size="large"
                        style={{ color: '#000' }}
                        onClick={() => onDelete(item)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  )
}
