import React, { FC, useMemo, useRef, useState, useEffect } from 'react'

/* mui */
import {
  Stack,
  Box,
  Button,
  useTheme,
  Snackbar,
  Typography,
} from '@mui/material'
import MicIcon from '@mui/icons-material/Mic'
import useSound from 'use-sound'
import FormControlLabel from '@mui/material/FormControlLabel'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import DownloadIcon from '@mui/icons-material/Download'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import useReactRouter from 'use-react-router'
import { theme } from '../styles/theme'
import { CustomFab } from '../components/molecules/CustomFab'
import Checkbox from '@mui/material/Checkbox'
import { yakurekiDetails } from '../constants/dummyData'
import { Copyright } from '../components/molecules/Copyright'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { DrawerToggleButton } from '../hooks/useDrowerToggle'
import { RecordModalBody } from '../components/templetes/RecordModalBody'
import { useModal } from '../hooks/useModal'
import { useRecordAudio } from '../hooks/useRecordAudio'
import { OnProgressOverlay } from '../components/molecules/OnProgressOverlay'
import { useYakurekiList } from '../hooks/useYakurekiList'
import { useSelectedYakureki } from '../hooks/useSelectedYakureki'
import { useLocation } from 'react-router-dom'
import { useYakurekiDetail } from '../hooks/useYakurekiDetail'
import { downloadCSV } from '../apis/csv'
import { SoapCheckBoxes } from '../components/organisms/SoapCheckBoxes'
import { soapLabels } from '../constants/const'
import { SelectedSoaps, SoapLabels } from '../types/yakureki'
import { SoapLoadingToast } from '../components/templetes/SoapLoadingToast'
import { SpeechToText } from '../components/templetes/SpeechToText'
import { DictatedContentTypography } from '../components/molecules/DictatedContentTypography'

const IndexPage: FC = () => {
  const { history } = useReactRouter()
  const { yakurekiList, loadYakurekiList } = useYakurekiList()
  const { selectedYakureki, selectRequest } = useSelectedYakureki(yakurekiList)
  const {
    yakurekiDetail,
    loadYakurekiDetail,
    soapList,
    selectedSoaps,
    setSelectedSoaps,
  } = useYakurekiDetail(selectedYakureki?.id)

  const [isAudioStarted, setIsAudioStarted] = useState(false)
  const [name, setName] = useState('')

  const audioRef = useRef<HTMLAudioElement>(null)

  const { isRecording, audioURL, recordButton } = useRecordAudio(
    name,
    setName,
    () => closeEditableModal(),
    loadYakurekiList
  )

  const playSound = () => {
    if (audioRef.current) {
      if (isAudioStarted) {
        audioRef.current.pause()
      } else {
        audioRef.current.currentTime = 0
        audioRef.current.play()
      }
      setIsAudioStarted(!isAudioStarted)
    }
  }

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)

  const copyToClipboard = async () => {
    const copyStr = selectedSoaps
      ? Object.entries(selectedSoaps)
          .map(([key, values]) => {
            if (key === 'id') return '' // IDを無視
            const content = Array.isArray(values) ? values.join('\n') : ''
            return content ? `${content}` : undefined
          })
          .filter(Boolean) // ここで undefined と空文字を除外
          .join('\n--------------------------------------\n')
      : ''

    await navigator.clipboard.writeText(copyStr)
    setIsSnackBarOpen(true)
    setTimeout(() => {
      setIsSnackBarOpen(false)
    }, 2000)
  }

  const isAllSelected =
    selectedSoaps &&
    soapList &&
    soapList['subjectives'].length === selectedSoaps['subjectives'].length &&
    soapList['objectives'].length === selectedSoaps['objectives'].length &&
    soapList['assessments'].length === selectedSoaps['assessments'].length &&
    soapList['educational_plans'].length ===
      selectedSoaps['educational_plans'].length &&
    soapList['care_plans'].length === selectedSoaps['care_plans'].length &&
    soapList['observational_plans'].length ===
      selectedSoaps['observational_plans'].length

  const deleteAll = () => {
    setSelectedSoaps({
      subjectives: [],
      objectives: [],
      assessments: [],
      educational_plans: [],
      care_plans: [],
      observational_plans: [],
    })
  }

  const selectAll = () => {
    setSelectedSoaps(soapList)
  }

  const deleteSpecificSoaps = (
    key: keyof SelectedSoaps,
    targetSoaps: string[]
  ) => {
    if (!selectedSoaps) return
    const filteredValues = selectedSoaps[key].filter(
      (item) => !targetSoaps.includes(item)
    )
    const newSoaps = {
      ...selectedSoaps,
      [key]: filteredValues,
    }
    setSelectedSoaps(newSoaps)
  }

  const addSpecificSoaps = (
    key: keyof SelectedSoaps,
    targetSoaps: string[]
  ) => {
    if (!selectedSoaps) return
    const currentSoaps = new Set(selectedSoaps[key])
    targetSoaps.forEach((soap) => currentSoaps.add(soap))

    const updatedValues = Array.from(currentSoaps) // Setを配列に変換
    const newSoaps = {
      ...selectedSoaps,
      [key]: updatedValues,
    }

    setSelectedSoaps(newSoaps)
  }

  const updateSelectedSoaps = (key: keyof SelectedSoaps, value: string) => {
    if (!selectedSoaps) return
    const isElementExist = selectedSoaps[key].includes(value)
    isElementExist ? deleteElement(key, value) : addElement(key, value)
  }

  const deleteElement = (key: keyof SelectedSoaps, value: string) => {
    if (!selectedSoaps) return
    const filteredValues = selectedSoaps[key].filter((item) => item !== value)
    const newSoaps = {
      ...selectedSoaps,
      [key]: filteredValues,
    }
    setSelectedSoaps(newSoaps)
    console.log('delete', newSoaps)
  }

  const addElement = (key: keyof SelectedSoaps, value: string) => {
    if (!selectedSoaps) return
    const targetAddedSoaps = [...selectedSoaps[key], value]
    const newSoaps = {
      ...selectedSoaps,
      [key]: targetAddedSoaps,
    }
    setSelectedSoaps(newSoaps)
    console.log('add', newSoaps)
  }

  const {
    openModal: openEditableModal,
    closeModal: closeEditableModal,
    renderModal: renderEditableModal,
  } = useModal(
    <RecordModalBody
      name={name}
      setName={setName}
      isRecording={isRecording}
      audioURL={audioURL}
      recordButton={recordButton}
    />,
    'center'
  )

  const NavigateAdaptationPageButton = (
    <>
      <Button
        sx={{ width: '100%', whiteSpace: 'pre-wrap', color: '#fff' }}
        onClick={() => history.push('/adaptation')}
      >
        {'文字起こし学習'}
      </Button>
    </>
  )

  // const drawerButtomButtons = [NavigateAdaptationPageButton]
  const drawerButtomButtons = [] as JSX.Element[]

  if (!yakurekiDetail)
    return (
      <div>
        {yakurekiList.map((yakureki, index) => (
          <SoapLoadingToast
            key={yakureki.id}
            yakureki={yakureki}
            index={index}
          />
        ))}
        <DrawerToggleButton
          selectedYakureki={selectedYakureki}
          yakurekiList={yakurekiList}
          selectYakureki={selectRequest}
          BottomButtons={drawerButtomButtons}
        />
        <Stack width="100%" direction="column" justifyContent="center">
          <CustomFab
            onClick={openEditableModal}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.primary.translucent,
                color: '#fff',
              },
              position: 'absolute', // 画面内の固定位置に配置する
              top: '50%', // 垂直中央に配置
              left: '50%', // 水平中央に配置
              transform: 'translate(-50%, -50%)', // 正確な中央に配置するための調整
              width: 400, // ボタンの幅を指定
              height: 400, // ボタンの高さを指定
              bottom: 'auto', // 元のbottom設定を無効化
            }}
          >
            <MicIcon style={{ fontSize: 320 }} /> {/* アイコンサイズを調整 */}
          </CustomFab>
        </Stack>
        {renderEditableModal()}
      </div>
    )

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      {/* <SpeechToText /> */}
      {yakurekiList.map((yakureki, index) => (
        <SoapLoadingToast key={yakureki.id} yakureki={yakureki} index={index} />
      ))}

      <DrawerToggleButton
        selectedYakureki={selectedYakureki}
        yakurekiList={yakurekiList}
        selectYakureki={selectRequest}
        BottomButtons={drawerButtomButtons}
      />
      <Box p={2} pr={5} width="50%" bgcolor="#fff">
        <Box
          p={1}
          width="100%"
          bgcolor="#fff"
          borderRadius="8px 8px 8px 8px"
          sx={{ border: '2px solid' }}
        >
          <Stack
            pt={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography fontSize={24} textAlign="center" sx={{ flex: 1 }}>
              文字起こし結果
            </Typography>
            <>
              <audio ref={audioRef} src={yakurekiDetail.audio.audio} />
              <Tooltip title="服薬指導の音声を再生" placement="top" arrow>
                <IconButton size="small" onClick={() => playSound()}>
                  {isAudioStarted ? (
                    <PauseCircleIcon fontSize="large" />
                  ) : (
                    <PlayCircleFilledWhiteIcon fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>
            </>
          </Stack>
          <Stack p={2} pb={1} spacing={1}>
            {yakurekiDetail.dictated &&
              yakurekiDetail.dictated.dictated_json.map((sentence) => (
                <DictatedContentTypography
                  key={sentence.fixed_content}
                  speaker={sentence.speaker}
                  original_string={sentence.fixed_content}
                  lack_confident_strings={sentence.major_fixes.map(
                    (fix) => fix.fixed
                  )}
                />
              ))}
            <Typography
              textAlign={'right'}
              fontSize={12}
              sx={{ color: '#A1A3A6' }}
            >
              ※文字が灰色の部分はAIが自信を持って認識できなかった部分です。
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Box width="50%">
        <div
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'scroll',
            // height: '100vh',
          }}
        >
          <Stack bgcolor={'primary.main'} p={2} spacing={1}>
            <Box>
              <CustomFab
                onClick={openEditableModal}
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.translucent,
                    color: '#fff',
                  },
                  bottom: 90,
                }}
              >
                <MicIcon style={{ fontSize: 35 }} />
              </CustomFab>

              <CustomFab
                onClick={() => downloadCSV()}
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.translucent,
                    color: '#fff',
                  },
                  bottom: 20,
                }}
              >
                <DownloadIcon style={{ fontSize: 35 }} />
              </CustomFab>

              <Stack
                p={2}
                bgcolor="#fff"
                borderRadius="8px 8px 8px 8px"
                direction="column"
                alignItems="flex-start"
                sx={{ border: '2px solid' }}
              >
                <Stack
                  pr={4}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Typography fontSize={24} textAlign="center" sx={{ flex: 1 }}>
                    SOAP
                  </Typography>
                  <>
                    <Tooltip title="SOAP内容をコピー" placement="top" arrow>
                      <IconButton
                        size="small"
                        onClick={() => copyToClipboard()}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Snackbar
                      sx={{ width: 40 }}
                      open={isSnackBarOpen}
                      message="コピーしました"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    />
                  </>
                </Stack>
                <Stack spacing={2}>
                  <FormControlLabel
                    sx={{ color: '#000', alignItems: 'start' }}
                    control={
                      <Checkbox
                        sx={{ height: 24, pr: 1, pl: 0.6 }}
                        size="small"
                        color="info"
                        checked={isAllSelected}
                        onChange={(e) =>
                          isAllSelected ? deleteAll() : selectAll()
                        }
                        value={'全選択/全解除'}
                      />
                    }
                    label={'全て選択/解除'}
                  />
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.subjectives.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'subjectives'}
                        targetSoapContents={yakurekiDetail.soaps.subjectives}
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.objectives.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'objectives'}
                        targetSoapContents={yakurekiDetail.soaps.objectives}
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.assessments.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'assessments'}
                        targetSoapContents={yakurekiDetail.soaps.assessments}
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.educational_plans.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'educational_plans'}
                        targetSoapContents={
                          yakurekiDetail.soaps.educational_plans
                        }
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.care_plans.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'care_plans'}
                        targetSoapContents={yakurekiDetail.soaps.care_plans}
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                  {yakurekiDetail.soaps &&
                    yakurekiDetail.soaps.observational_plans.length !== 0 &&
                    selectedSoaps && (
                      <SoapCheckBoxes
                        soapKey={'observational_plans'}
                        targetSoapContents={
                          yakurekiDetail.soaps.observational_plans
                        }
                        selectedSoapContents={selectedSoaps}
                        deleteSpecificSoaps={deleteSpecificSoaps}
                        addSpecificSoaps={addSpecificSoaps}
                        update={updateSelectedSoaps}
                      />
                    )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {/* <Stack px={2} p={2}>
            <Stack
              p={3}
              pt={2}
              pb={2}
              bgcolor="#fff"
              borderRadius="8px 8px 8px 8px"
              sx={{ border: '2px solid' }}
            >
              <Typography fontSize={24} textAlign="center" sx={{ flex: 1 }}>
                服薬指導FB
              </Typography>
              <Stack spacing={1}>
                <Typography>
                  スコア：
                  {yakurekiDetail.feedBack && yakurekiDetail.feedBack.score}
                </Typography>
                <Typography>
                  フィードバック:
                  {yakurekiDetail.feedBack && yakurekiDetail.feedBack.script}
                </Typography>
              </Stack>
            </Stack>
          </Stack> */}

          <Box p={2}>
            <Copyright />
          </Box>
        </div>
        {renderEditableModal()}
      </Box>
    </div>
  )
}

export default IndexPage
