import { SoapLabels } from '../types/yakureki'

export const API_HOST = process.env.REACT_APP_API_HOST

export const serviceName = 'Kakeru君'

export const soapLabels = {
  subjectives: '【S】',
  objectives: '【O】',
  assessments: '【A】',
  educational_plans: '【EP】',
  care_plans: '【CP】',
  observational_plans: '【OP】',
} as SoapLabels
