import React from 'react'
import client from './client'

export const downloadCSV = async () => {
  try {
    const response = await client.get('/api/csv', {
      responseType: 'blob', // Important for handling file downloads
    })

    // Create a URL for the downloaded file
    const url = window.URL.createObjectURL(new Blob([response.data]))

    // Create a link element
    const link = document.createElement('a')

    const today = new Date()
    const yyyy = today.getFullYear()
    const mm = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-indexed, so +1
    const dd = String(today.getDate()).padStart(2, '0')
    const formattedDate = `${yyyy}${mm}${dd}`

    // Get the suggested filename from the response headers
    const contentDisposition = response.headers['content-disposition']
    let fileName = `${formattedDate}_feedback.csv`
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
    }

    link.href = url
    link.setAttribute('download', fileName) // Set the filename
    document.body.appendChild(link)

    // Programmatically click the link to trigger the download
    link.click()

    // Clean up
    document.body.removeChild(link)
  } catch (error) {
    console.error('There was an error downloading the CSV:', error)
  }
}
