import axios from 'axios'
import { API_HOST } from '../constants/const'

let client
console.log('API_HOST: ', API_HOST)

export default client = axios.create({
  baseURL: `${API_HOST}`,
  headers: {
    'Content-Type': 'application/json',
    REFERER: 'http://127.0.0.1:3000',
  },
  withCredentials: true,
})
